import { PromoDiscountType, usePromotions, useValidatedPromo } from 'api/goodtrust/promo'
import { getUserMe, useUserMe } from 'api/goodtrust/user'
import { useOfferModal } from 'components/modal/offerModal/useOfferModal'
import { Perk } from 'components/perkItem/PerkItem'
import { handleAndToastError } from 'components/Toast'
import { isAfter, isBefore, sub } from 'date-fns'
import { describePromotion } from 'logic/promo/describe'
import { describePromoCodes } from 'logic/promo/describePromoCodes'
import { describePromoOffer } from 'logic/promo/describePromoOffer'
import { describeMyPlansForPlanGroup } from 'logic/subscription/plan/my/group/describe'
import { planSpecs } from 'logic/subscription/plan/spec'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import useSWR, { mutate, SWRResponse } from 'swr'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { getCobrandedPartnerSlug } from 'utils/cobranding/logic'
import { ShouldNeverHappenError } from 'utils/error'
import { unwrapResponse } from 'utils/fetcher'
import { ident, isNonNullable, isOneOf, keysOf, matchMap, toLowerCase } from 'utils/general'
import { EstatePlanningPriceType } from 'utils/getInitialEstatePlanningPrice'
import { useLatestValueRef, useRunOnceAllValuesAreDefined, useRunOnceReady } from 'utils/hooks'
import { offerSpecs } from 'utils/promo/spec'
import { ApiType } from 'utils/types'
import { joinHumanly } from './format'

const promoCodeQueryParamName = 'promo'
const sessionStoragePromoCodeKey = '@goodtrust/promoCode'
export const swrPromoCodeKey = '@goodtrust/promoCode'

export function useSavePromoCodeFromURL() {
  const router = useRouter()
  const { isLogged } = useAuth()
  const hasLoginData = isLogged != null
  const promoCodeSwr = useStoredPromoCodeSwr()

  useRunOnceReady(hasLoginData && router.isReady, () => {
    if (isLogged) {
      // we only want to store the promo if the user doesn't already have an account
      // https://cleevio.atlassian.net/wiki/spaces/GT/pages/2692579331/Promotion+code+in+URL#Logged-in-user
      // we are using useRunOnceReady so that we wait for the moment when we know whether the user is logged in
      return
    }

    tryStorePromoCode(router.query[promoCodeQueryParamName])
    promoCodeSwr.mutate()
  })
}

export function useStoredPromoCodeSwr() {
  return useSWR(swrPromoCodeKey, tryLoadStoredPromoCode)
}

/**
 * This hook provides two ways of working with a stored promo code (from URL).
 * The hook user should choose one of the ways.
 *
 * ### Callback way
 * The hook user passes `onValidatedCode` callback and it gets called once the stored code is validated and is valid.
 * Once the callback is called, the hook user is responsible for showing an offer modal or doing its specific thing with the promo code.
 *
 * ### On registered way
 * The hook provides the `onRegistered` function in its return value.
 * The hook user is expected to call `onRegistered` right after the user gets registered.
 * Then, `useClaimStoredPromoCode` loads the stored promo code.
 * Then, it checks with the API whether the code is valid and if so, opens offer modal.
 * The hook user is also expected to provide the `jsx` property to the React tree to allow this hook to render the offer modal.
 */
export function useStoredPromoCodeLogic(args?: { onValidatedCode: (code: string) => void }) {
  const swr = useStoredPromoCodeSwr()
  const code = swr.data
  const offerModal = useOfferModal()
  const argsRef = useLatestValueRef(args)
  const promoSwr = useExtendedPromotion(code ?? undefined)

  useRunOnceAllValuesAreDefined([promoSwr.data] as const, ([promo]) => {
    argsRef.current?.onValidatedCode(promo.code)
  })

  const onRegistered = async () => {
    try {
      if (!code) return

      const [userMe, promo] = await Promise.all([
        getUserMe().then(unwrapResponse.body),
        promoSwr.mutate(),
      ])

      if (!userMe.registered || !promo) return

      // if current time - 5 minutes is before registration time
      // it means the user has registered in the last 5 minutes
      const hasRecentlyRegistered = isBefore(
        sub(new Date(), {
          minutes: 5,
        }),
        new Date(userMe.registered)
      )

      if (!hasRecentlyRegistered) return

      const result = await offerModal.open(promo.code)
      if (result.type === 'continue') {
        await describePromoCodes(code).handleClaimPromoCode(result.info)
      }
      //
    } catch (err) {
      handleAndToastError(err)
    } finally {
      tryRemovePromoCodeFromStorage()
    }
  }

  return { jsx: offerModal.jsx, onRegistered }
}

export function extractPromoOffers(args: {
  promo: ApiType['PromoCodeResponse']
  discountType?: PromoDiscountType
}) {
  // there can be at most one subscription offer
  // see spec https://cleevio.atlassian.net/wiki/spaces/GT/pages/2688090161/Merging+offers+to+1+promotion#Which-offers-can-be-combined
  const freeSubOffer = args.promo.offers?.find((offer) =>
    describePromoOffer(offer).asFreeSubscription()
  )

  // there can be 0+ discount offers
  const discountOffers = args.promo.offers?.filter((offer) =>
    describePromoOffer(offer).asDiscount()
  )
  const usableDiscountOffer = discountOffers?.find((offer) =>
    describePromoOffer(offer).doesOfferLimitConformToCheckout(args.discountType)
  )

  return {
    freeSubOffer,
    discountOffers,
    usableDiscountOffer,
  }
}

export function tryStorePromoCode(codeInQuery?: string | string[]) {
  try {
    const code = Array.isArray(codeInQuery) ? codeInQuery[0] : codeInQuery
    if (!code) return

    sessionStorage.setItem(sessionStoragePromoCodeKey, code)
    mutate(swrPromoCodeKey)
  } catch (err) {}
}

function tryRemovePromoCodeFromStorage() {
  try {
    sessionStorage.removeItem(sessionStoragePromoCodeKey)
    mutate(swrPromoCodeKey)
  } catch (err) {}
}

function tryLoadStoredPromoCode(): string | null | undefined {
  try {
    return sessionStorage.getItem(sessionStoragePromoCodeKey)
  } catch {
    return undefined
  }
}

export function isPromotionExpired(
  promo: ApiType['PromoCodeInfoResponse'] | ApiType['ClaimedPromoCodeResponse'],
  expirationType: 'for-claiming' | 'for-redeeming'
) {
  return !!determinePromotionUsageRangePosition(promo, expirationType)?.isAfter
}

export function isPromotionBeforeValidRange(
  promo: ApiType['PromoCodeInfoResponse'] | ApiType['ClaimedPromoCodeResponse']
) {
  return !!determinePromotionUsageRangePosition(promo, 'for-claiming')?.isBefore
}

export function determinePromotionUsageRangePosition(
  promo: ApiType['PromoCodeInfoResponse'] | ApiType['ClaimedPromoCodeResponse'],
  expirationType: 'for-claiming' | 'for-redeeming'
) {
  const deadline = new Date(
    matchMap(expirationType, {
      'for-claiming': promo.to,
      'for-redeeming': promo.use_before,
    }) ?? ''
  ).getTime()
  const rangeStart = new Date(promo.from ?? '').getTime()
  if (Number.isNaN(deadline) || Number.isNaN(rangeStart)) return undefined

  const rangePosition = {
    isAfter: isAfter(Date.now(), deadline),
    isBefore: isBefore(Date.now(), rangeStart),
  }

  const isOutside = rangePosition.isBefore || rangePosition.isAfter
  const isInside = !isOutside

  return {
    ...rangePosition,
    isOutside,
    isInside,
  }
}

export function canRedeemDiscountOffer(
  promo: ApiType['ClaimedPromoCodeResponse'] | ApiType['PromoCodeInfoResponse'],
  offer: ApiType['ClaimedOfferResponse'],
  discountType?: PromoDiscountType
) {
  return (
    describePromoOffer(offer).doesOfferLimitConformToCheckout(discountType) &&
    getRemainingUsages(offer) > 0 &&
    !isPromotionExpired(promo, 'for-redeeming')
  )
}

const pluralizeMonths = (count: number | undefined) => {
  if (count && count > 1) {
    return `${count} months`
  }

  return `1 month`
}

export function getRemainingUsages(offer: ApiType['ClaimedOfferResponse']) {
  const usageLimit = offer.usage_limit === -1 ? Infinity : offer.usage_limit ?? 1
  const remainingUses = usageLimit - (offer?.usage ?? 0)

  return remainingUses
}

export function extendPromotion(
  promo: ApiType['PromoCodeResponse'] | ApiType['PromoCodeValidationResponse'],
  claimedPromotions: ApiType['ClaimedListResponse']
) {
  const isExpiredForRedeeming = isPromotionExpired(promo.promo_code_info ?? {}, 'for-redeeming')
  const isExpiredForClaiming = isPromotionExpired(promo.promo_code_info ?? {}, 'for-claiming')
  const isBeforeValidRange = isPromotionBeforeValidRange(promo.promo_code_info ?? {})
  const claimedPromo = claimedPromotions.promo_codes?.find(
    (item) => item.code === promo.promo_code_info?.code
  )
  const claimedOffers = claimedPromo?.claimed_offers

  const offers = (promo?.offers ?? []).map((offer) => {
    const claimedOffer = claimedOffers?.find((item) => item.uuid === offer.uuid)

    const isFullyRedeemed = claimedOffer && getRemainingUsages(claimedOffer) <= 0

    return {
      ...offer,
      claimedOffer,
      isFullyRedeemed,
    }
  })
  const freeSubOffers = offers?.flatMap((offer) =>
    describePromoOffer(offer).asFreeSubscription() ? [offer] : []
  )
  const discountOffers = offers.flatMap((offer) =>
    describePromoOffer(offer).asDiscount() ? [offer] : []
  )
  const animationPackOffer = offers.flatMap((offer) =>
    describePromoOffer(offer).asAnimationPack() ? [offer] : []
  )[0]

  const offerErrorCodes = offers
    .map((offer) => ('error_code' in offer ? offer.error_code : null))
    .filter(isNonNullable)

  const cannotClaimReason = claimedPromo
    ? 'already_claimed'
    : isExpiredForClaiming
    ? 'expired_for_claiming'
    : offerErrorCodes.includes('411')
    ? 'reached_max_users'
    : isBeforeValidRange
    ? 'before_valid_range'
    : offerErrorCodes.includes('promo_code_usage_exceeded')
    ? 'promo_code_usage_exceeded'
    : undefined

  function findRedeemableDiscountOffer(discountType?: PromoDiscountType) {
    return discountOffers.find(
      (offer) =>
        promo.promo_code_info && canRedeemDiscountOffer(promo.promo_code_info, offer, discountType)
    )
  }

  if (!promo.promo_code_info?.code) throw new ShouldNeverHappenError()

  const cobrandedPartnerSlug = getCobrandedPartnerSlug(promo.promo_code_info.code)

  const cobrandedPartner =
    // @ts-ignore
    promo?.promo_code_info?.co_branded_partner || cobrandedPartnerSlug
      ? promo.promo_code_info?.partner
      : undefined

  return {
    ...promo,
    active: promo.promo_code_info.active,
    code: promo.promo_code_info?.code,
    claimedOffers,
    claimedPromo,
    offers,
    freeSubOffers,
    animationPackOffer,
    discountOffers,
    isExpiredForRedeeming,
    isExpiredForClaiming,
    isBeforeValidRange,
    findRedeemableDiscountOffer,
    cannotClaimReason,
    canBeClaimed: cannotClaimReason == null,
    cobrandedPartner,
  } as const
}

export type ExtendedPromotion = ReturnType<typeof extendPromotion>

export function useEstatePlanPrice(estatePlanningPrice: EstatePlanningPriceType) {
  const userMe = useUserMe()
  const { isLogged } = useAuth()
  const { data: promotions } = usePromotions()
  const estate = describeMyPlansForPlanGroup(userMe.data?.json ?? {}, 'ESTATE')

  const swr = useStoredPromoCodeSwr()
  const code = isLogged ? promotions?.json?.promo_codes?.[0]?.code : swr.data
  const { mutate: mutatePromotion, data: promotion } = useExtendedPromotion(code)

  useEffect(() => {
    mutatePromotion()
  }, [isLogged, code, mutatePromotion])

  return estate.getEstatePlanningPriceForUser(estatePlanningPrice, !!isLogged, promotion)
}

export function useExtendedPromotion(
  code?: string | null
): SWRResponse<ExtendedPromotion | undefined> {
  return useSWR(`@goodtrust/extendedPromotion/${code}`, () =>
    code ? describePromoCodes(code).getExtendedPromotion() : undefined
  )
}

export function useAolPromotionCode(code?: string | null, aol?: boolean) {
  return useSWR(`@goodtrust/aolCode/${code}`, () =>
    code && aol ? describePromoCodes(code).getAolPromotion() : undefined
  )
}

export function usePerksOfPromotion(code?: string): Perk[] {
  const promoSwr = useExtendedPromotion(code)
  const validationSwr = useValidatedPromo(code)

  const promo = promoSwr.data
  const { t } = useTranslation('pricing_plan')

  if (!promo) return []
  const promoDescription = describePromotion(promo)

  const perks: Perk[] = [
    ...(promo.freeSubOffers ?? []).map((anyOffer): Perk | undefined => {
      const offer = describePromoOffer(anyOffer).asFreeSubscription()
      if (!offer) throw new ShouldNeverHappenError()
      const { type: offerType } = offer

      const offerSpec = offerSpecs[offerType]
      if (!offerSpec.freeSubscription) throw new ShouldNeverHappenError()

      const planSpec = planSpecs[offerSpec.freeSubscription]
      const description = t(`pricing_plan.offer.${toLowerCase(offerType)}.description`, {
        amount: planSpec.animationsPerMonth ?? planSpec.animationsPerWeek,
      })
      const title = `${
        describePromoOffer(offer).isLifetimeOffer() ? 'Lifetime' : pluralizeMonths(offer.amount)
      } of GoodTrust ${planSpec.displayName} plan for Free`

      const validatedOffer = validationSwr.data?.json?.offers?.find((o) => o.uuid === offer.uuid)

      if (validatedOffer && !promoDescription.canRedeemSubscriptionOffer(validatedOffer))
        return {
          title: t('pricing_plan.offer.not_eligible'),
          description: title,
          variant: 'disabled',
        }

      return offer
        ? {
            title,
            description,
          }
        : undefined
    }),
    ...(promo.discountOffers ?? []).map((offer): Perk | undefined => {
      const purchaseType = isOneOf('ALL', offer.discount_types ?? []) ? 'any' : 'specific'
      const title = t('pricing_plan.offer.discount.title.format', {
        amount: describePromoOffer(offer).formatOfferDiscount(),
        purchaseType: t(`pricing_plan.offer.discount.title.purchase_type.${purchaseType}`),
      })
      const descriptionList = expandDiscountTypes(offer.discount_types)
        .map((type) => {
          return t(`pricing_plan.offer.discount.description.type.${type}`)
        })
        .filter(isNonNullable)

      const description = t('pricing_plan.offer.discount.description.format', {
        list: joinHumanly(descriptionList),
      })

      if (offer.isFullyRedeemed) {
        return {
          title: t('pricing_plan.offer.already_redeemed'),
          description: title,
          variant: 'disabled',
        }
      }

      return {
        title,
        description,
      }
    }),
    promo.animationPackOffer
      ? promo.animationPackOffer.isFullyRedeemed
        ? {
            title: t('pricing_plan.offer.already_redeemed'),
            variant: 'disabled' as const,
            description: t('pricing_plan.offer.animation_pack_free.description', {
              amount: promo.animationPackOffer.amount,
            }),
          }
        : {
            title: t('pricing_plan.offer.animation_pack_free.title', {
              count: promo.animationPackOffer.amount,
            }),
            description: t('pricing_plan.offer.animation_pack_free.description', {
              count: promo.animationPackOffer.amount,
            }),
          }
      : undefined,
  ].filter(isNonNullable)

  return perks
}

export const EVERY_DISCOUNT_TYPE = keysOf(
  ident<Record<PromoDiscountType, ''>>({
    ESTATE_PLAN: '',
    DIGITAL_SAFETY: '',
    ULTIMATE_PLAN: '',
    WGS: '',
    ANIMATION_SUBSCRIPTION: '',
    ANIMATION_PACK: '',
    ESTATE_FAMILY_PLAN: '',
  })
)

export function expandDiscountTypes(
  discountTypes: ApiType['OfferResponse']['discount_types'] | undefined
): PromoDiscountType[] {
  const includesAll = isOneOf('ALL', discountTypes ?? [])
  if (!includesAll)
    return (
      discountTypes?.map((type) => {
        if (type === 'ALL') {
          // ALL should have been filtered out.
          throw new ShouldNeverHappenError()
        }
        return type
      }) ?? []
    )

  return EVERY_DISCOUNT_TYPE
}
